import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@layouts';
import { Banners, SEO, Home } from '@components';
import HomeVideo from '../popups/HomeVideo';

const { HomeBanner } = Banners;
const Index = ({ data, location }) => {
  const { posts } = data;

  return (
    <Layout bannerChildren={<HomeBanner />}>
      <SEO title='HOME' description='Positively Changing the view of the African Continent' />

      <Home articleData={posts} location={location} />
      <HomeVideo vidURL='https://youtu.be/IVxqxpb-PRU' />
    </Layout>
  );
};

export const query = graphql`
  query {
    posts: allMarkdownRemark(
      filter: { frontmatter: { content_type: { in: ["article", "feature"] } } }
      sort: { fields: [frontmatter___date, frontmatter___title], order: [DESC, ASC] }
    ) {
      edges {
        node {
          frontmatter {
            content_type
            slug
            title
            subject
            excerpt
            author
            photo_credit
            category
            date
            image_url {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
            image_1 {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Index;
