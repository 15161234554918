import React, { useContext, useRef } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
// import * as styles from './HomeVideo.module.scss';
import '../../styles/popupVideos.scss';
import { GeneralContext } from '../../context/GeneralContext';

export default function HomeVideo({ vidURL }) {
  const modalValue = useContext(GeneralContext);
  const closeBtn = useRef();
  const modalBG = useRef();

  const closeModal = (e) => {
    if (
      e.target === closeBtn.current ||
      e.target === modalBG.current ||
      e.target.parentNode === closeBtn.current
    ) {
      modalValue.setHomeVideoModal(false);
    }
  };

  return (
    <div
      className={`${modalValue.homeVideoModal ? 'openModal' : null} wrapper`}
      onClick={closeModal}
      ref={modalBG}
      id='popup-video'
    >
      <div className='holder'>
        <button
          className='closeModal mb-auto'
          type='button'
          onClick={closeModal}
          ref={closeBtn}
          aria-label='Closes popup video about company at start of homepage'
        >
          <h3>X</h3>
        </button>

        <div className='w-100 h-100 mx-auto text-center'>
          {!vidURL && (
            <ReactPlayer
              width='100%'
              height='100%'
              controls
              playing={modalValue.homeVideoModal}
              url='https://youtu.be/IVxqxpb-PRU'
            />
          )}
          {vidURL && (
            <ReactPlayer
              width='100%'
              height='100%'
              controls
              playing={modalValue.homeVideoModal}
              url={vidURL}
            />
          )}
        </div>
      </div>
    </div>
  );
}

HomeVideo.defaultProps = {
  vidURL: 'https://youtu.be/IVxqxpb-PRU',
};

HomeVideo.propTypes = {
  vidURL: PropTypes.string,
};
